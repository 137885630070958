/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"

import "./layout.css"

const Layout: React.FunctionComponent = ({ children }) => {
  return <div id="container">{children}</div>
}

export default Layout
