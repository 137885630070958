/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { useLocation } from "@reach/router"

import favicon from "../images/favicon.ico"

export interface SEOProps {
  description?: string
  lang?: string
  meta?: JSX.IntrinsicElements["meta"][]
  title: string
  image?: string
}

export default function SEO({ description, lang = "en", meta = [], title, image }: SEOProps) {
  interface QueryData {
    site: {
      siteMetadata: {
        title: string
        url: string
        description: string
      }
    }
  }

  const { site } = useStaticQuery<QueryData>(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            url
          }
        }
      }
    `
  )

  const { pathname } = useLocation()

  const metaDescription = description || site.siteMetadata.description
  const siteTitle = site.siteMetadata.title
  const url = `${site.siteMetadata.url}${pathname}`

  const defaultMeta: JSX.IntrinsicElements["meta"][] = [
    {
      name: `description`,
      content: metaDescription,
    },
    {
      property: `og:title`,
      content: title,
    },
    {
      property: `og:description`,
      content: metaDescription,
    },
    {
      property: `og:type`,
      content: `website`,
    },
    {
      property: `og:url`,
      content: url,
    },
    {
      name: `twitter:card`,
      content: `summary`,
    },
    {
      name: `twitter:title`,
      content: title,
    },
    {
      name: `twitter:description`,
      content: metaDescription,
    },
  ]

  if (image) {
    defaultMeta.push({
      property: `og:image`,
      content: image,
    })
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={siteTitle ? `%s | ${siteTitle}` : "%s"}
      meta={defaultMeta.concat(meta)}
    >
      <link rel="icon" href={favicon} />
    </Helmet>
  )
}
